import { Button } from '@trmediaab/zebra';
import { useContext } from 'react';

import AccountIcon from '~/icons/AccountCircle';
import CloseIcon from '~/icons/Close';
import MenuIcon from '~/icons/Menu';

import { HeaderContext } from './HeaderContext';

const MobileNav = () => {
  const {
    mobile: { navVisible, onToggleAccount, onToggleNav },
  } = useContext(HeaderContext);
  const NavComponent = navVisible ? CloseIcon : MenuIcon;

  return (
    <>
      <Button onClick={onToggleAccount}>
        <AccountIcon size="24px" color="greys.3" />
      </Button>
      <Button onClick={onToggleNav} ml="3">
        <NavComponent size="24px" color="greys.3" />
      </Button>
    </>
  );
};

export default MobileNav;
