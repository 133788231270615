import { useEffect, useRef, useState } from 'react';

const restCache = {};

function saveBlob(fileName, blob) {
  // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob);
    return;
  }

  // For other browsers: create a link pointing to the ObjectURL containing the blob.
  const objUrl = window.URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = objUrl;
  link.download = fileName;
  link.click();

  // For Firefox it is necessary to delay revoking the ObjectURL.
  setTimeout(() => {
    window.URL.revokeObjectURL(objUrl);
  }, 250);
}

const useRestQuery = query => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    const { path, method = 'GET', data: body, headers: queryHeaders } = query;

    async function fetchData() {
      try {
        const headers = {
          Accept: 'application/json',
          ...(body != null && { 'Content-Type': 'application/json' }),
          ...queryHeaders,
        };
        const response = await fetch(path, {
          method,
          headers,
          ...(method !== 'GET' &&
            body != null && { body: JSON.stringify(body) }),
          credentials: 'include',
        });

        if (response.ok) {
          const disposition = response.headers.get('Content-Disposition');
          if (disposition != null && disposition.includes('attachment')) {
            const filenameRegex = /filename[^\n;=]*=((["']).*?\2|[^\n;]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              const filename = matches[1].replace(/["']/g, '');
              const blob = await response.blob();
              saveBlob(filename, blob);
              setData({ filename });
            } else {
              throw new Error('Ett fel inträffade, försök igen');
            }
          } else if (
            response.headers.get('Content-Type') === 'application/json'
          ) {
            const json = await response.json();

            if (method === 'GET') {
              restCache[path] = json;
            }

            if (mounted.current) {
              setData(json);
            }
          } else {
            throw new Error('Ett fel inträffade, försök igen');
          }
        } else {
          let errorMessage = response.statusText;

          try {
            const json = await response.json();
            errorMessage = json.detail ?? errorMessage;
          } catch {
            // Continue
          }

          const error = new Error(errorMessage);
          error.statusCode = response.status;
          throw error;
        }
      } catch (error) {
        if (mounted.current) {
          setError(error);
        }
      }
    }

    if (restCache[path] != null) {
      setData(restCache[path]);
    } else {
      fetchData();
    }

    return () => {
      mounted.current = false;
    };
  }, [query]);

  return {
    data,
    error,
    loading: data == null && error == null,
  };
};

export default useRestQuery;
