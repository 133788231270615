import { propType as styledSystemPropType } from '@styled-system/prop-types';
import { Anchor, Box, List } from '@trmediaab/zebra';
import Link from 'next/link';

const DesktopNav = ({ vspace }) => (
  <Box py={vspace}>
    <List axis="horizontal" gap="4">
      <List.listItem>
        <Anchor as={Link} href="/" variant="matchText" fontWeight="bold">
          Mitt konto
        </Anchor>
      </List.listItem>
      <List.listItem>
        <Anchor as={Link} href="/faq" variant="matchText" fontWeight="bold">
          Frågor och svar
        </Anchor>
      </List.listItem>
      <List.listItem>
        <Anchor as={Link} href="/kontakt" variant="matchText" fontWeight="bold">
          Kontakt
        </Anchor>
      </List.listItem>
    </List>
  </Box>
);

DesktopNav.propTypes = {
  vspace: styledSystemPropType.isRequired,
};

export default DesktopNav;
