import PropTypes from 'prop-types';

import Html from '~/components/Html';
import Query from '~/components/Query';

import SiteAlert from '../SiteAlert';

const query = {
  path: 'content.json?limit=1&type=userpages_alert&detail=true',
};

const RenderArticle = ({ article }) => (
  <SiteAlert type="article">
    <Html
      html={article.text}
      between="2"
      sx={{
        a: {
          color: 'inherit',
          textDecoration: 'underline',
        },
      }}
    />
  </SiteAlert>
);

RenderArticle.propTypes = {
  article: PropTypes.shape({
    text: PropTypes.string,
  }).isRequired,
};

const Alert = () => (
  <Query
    apiName="trais"
    query={query}
    wrap={(content, type) => (type === 'data' ? content : null)}
    render={data => {
      const {
        results: [article],
      } = data;

      // No alert published, render nothing
      if (article == null) {
        return null;
      }

      return <RenderArticle article={article} />;
    }}
  />
);

export default Alert;
