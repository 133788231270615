import {
  Anchor,
  BorderBox,
  Box,
  Container,
  Divider,
  Position,
} from '@trmediaab/zebra';
import { cloudinary } from '@trmediaab/zebra-utils';
import Link from 'next/link';

import DesktopAccount from './DesktopAccount';
import DesktopNav from './DesktopNav';
import HeaderProvider from './HeaderContext';
import { DesktopAccountMenu, MobileAccountMenu, MobileNavMenu } from './Menus';
import MobileNav from './MobileNav';

const SiteHeader = () => (
  <HeaderProvider>
    <Position.relative zIndex="pageHeader">
      <BorderBox
        as="header"
        bg="white"
        boxShadow="0"
        borderTop="3"
        borderColor="primary"
      >
        <Container flexDirection="row" py="2,5">
          <Box>
            <Anchor as={Link} href="/">
              <Box
                as="img"
                src={cloudinary.getUrl(
                  'brands/logos/si3okwhx7yghsm0fknwo',
                  116,
                )}
                alt="TR Media"
              />
            </Anchor>
          </Box>
          <Box ml="auto" display={['block', null, 'none']}>
            <MobileNav />
          </Box>
        </Container>
        <Box display={['none', null, 'block']}>
          <Divider />
          <Container flexDirection="row">
            <DesktopNav vspace="2,5" />
            <Box ml="auto">
              <DesktopAccount vspace="2,5" />
            </Box>
          </Container>
        </Box>
      </BorderBox>
    </Position.relative>
    <Position.relative
      zIndex="pageHeaderMenu"
      display={['none', null, 'block']}
    >
      <DesktopAccountMenu />
    </Position.relative>
    <Position.relative
      zIndex="pageHeaderMenu"
      display={['block', null, 'none']}
    >
      <MobileAccountMenu />
      <MobileNavMenu />
    </Position.relative>
  </HeaderProvider>
);

export default SiteHeader;
