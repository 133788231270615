import {
  Anchor,
  BorderBox,
  Box,
  Container,
  List,
  Position,
  Text,
} from '@trmediaab/zebra';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { useContext } from 'react';

import useAuthUser from '~/main/useAuthUser';

import LogoutButton from '../LogoutButton';
import { HeaderContext } from './HeaderContext';

const PositionedMobile = ({ visible, children }) => (
  <Position.absolute
    left="0"
    width="100%"
    height="100vh"
    bg="white"
    display={visible ? 'block' : 'none'}
  >
    <Container pt="3">{children}</Container>
  </Position.absolute>
);

PositionedMobile.propTypes = {
  visible: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const UserInfo = props => {
  const { name, email } = useAuthUser();

  return (
    <Box {...props}>
      Inloggad som <br />
      <Text.strong>{name != null ? name : email}</Text.strong>
      {name != null && (
        <>
          <br />
          <Text.strong>{email}</Text.strong>
        </>
      )}
    </Box>
  );
};

export const DesktopAccountMenu = () => {
  const {
    desktop: { menuRef, visible },
  } = useContext(HeaderContext);
  return (
    <Position.absolute
      ref={menuRef}
      left="0"
      width="100%"
      display={visible ? 'block' : 'none'}
      zIndex="0"
    >
      <BorderBox bg="white" py="4" boxShadow="0">
        <Container flexDirection="row" alignItems="center">
          <UserInfo />
          <Box ml="auto" width="240px">
            <LogoutButton />
          </Box>
        </Container>
      </BorderBox>
    </Position.absolute>
  );
};

export const MobileAccountMenu = () => {
  const {
    mobile: { accountVisible },
  } = useContext(HeaderContext);

  return (
    <PositionedMobile visible={accountVisible}>
      <UserInfo mb="4" />
      <LogoutButton />
    </PositionedMobile>
  );
};

export const MobileNavMenu = () => {
  const {
    mobile: { navVisible, handleMenuItemClick },
  } = useContext(HeaderContext);

  return (
    <PositionedMobile visible={navVisible}>
      <List divided gap="3">
        <List.listItem>
          <Anchor
            as={Link}
            href="/"
            variant="matchText"
            fontWeight="bold"
            onClick={handleMenuItemClick}
          >
            Mitt konto
          </Anchor>
        </List.listItem>
        <List.listItem>
          <Anchor
            as={Link}
            href="/faq"
            variant="matchText"
            fontWeight="bold"
            onClick={handleMenuItemClick}
          >
            Frågor och svar
          </Anchor>
        </List.listItem>
        <List.listItem>
          <Anchor
            as={Link}
            href="/kontakt"
            variant="matchText"
            fontWeight="bold"
            onClick={handleMenuItemClick}
          >
            Kontakt
          </Anchor>
        </List.listItem>
      </List>
    </PositionedMobile>
  );
};
