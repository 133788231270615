import TrFooter from '@trmediaab/trmedia-footer';
import { Box, Container, FlexBox } from '@trmediaab/zebra';
import PropTypes from 'prop-types';

import EditorialAlert from '../Content/Alert';
import ExpiringCardsAlert from '../ExipringCardsAlert';
import SiteHeader from '../SiteHeader';

const Layout = ({ children }) => (
  <FlexBox flexDirection="column" width="100%" minHeight="100vh">
    {/* Reversed order so that we get a good z-order when there are multiple alerts */}
    <FlexBox flexDirection="column-reverse">
      <ExpiringCardsAlert />
      <EditorialAlert />
    </FlexBox>
    <SiteHeader />
    <Box flex="1" mb="6">
      <Container>{children}</Container>
    </Box>
    <TrFooter />
  </FlexBox>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
