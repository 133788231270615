import { BorderBox, Box, FlexBox, Heading } from '@trmediaab/zebra';
import PropTypes from 'prop-types';

import Error from '~/icons/Error';

const errorType = PropTypes.shape({
  message: PropTypes.string.isRequired,
  networkError: PropTypes.shape({
    result: PropTypes.shape({
      message: PropTypes.string,
      errors: PropTypes.arrayOf(
        PropTypes.shape({
          extensions: PropTypes.shape({
            code: PropTypes.string,
          }),
          message: PropTypes.string.isRequired,
        }),
      ),
    }),
  }),
});

const ErrorDetail = ({ error: { networkError } }) =>
  networkError?.result?.errors?.length > 0 ? (
    <>
      {networkError.result.errors.map(
        ({ extensions: { code } = {}, message }) => (
          <Box key={message} mt="3">
            {code != null && <Heading.h5 mb="1">{code}</Heading.h5>}
            {message}
          </Box>
        ),
      )}
    </>
  ) : null;

ErrorDetail.propTypes = {
  error: errorType.isRequired,
};

// TODO - retry function
const ErrorMessage = ({ error }) => {
  const friendlyError =
    error.networkError?.result?.message != null ? (
      error.networkError.result.message
    ) : (
      <Box>Ett fel inträffade – {error.message}</Box>
    );

  return (
    <BorderBox
      border="1"
      borderRadius="1"
      borderColor="state.error"
      bg="state.errorBackground"
      p="3"
      color="state.error"
    >
      <FlexBox alignItems="start">
        <Error mr="2" size="24px" flexShrink="0" />
        {friendlyError}
      </FlexBox>

      {process.env.NODE_ENV === 'development' && <ErrorDetail error={error} />}
    </BorderBox>
  );
};

ErrorMessage.propTypes = {
  error: errorType.isRequired,
};

export default ErrorMessage;
