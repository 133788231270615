import { usePreventBodyScroll } from '@trmediaab/zebra-hooks';
import PropTypes from 'prop-types';
import { createContext, useCallback, useMemo, useRef, useState } from 'react';

export const HeaderContext = createContext();

const useMobile = () => {
  const [accountVisible, setAccountVisible] = usePreventBodyScroll();
  const [navVisible, setNavVisible] = usePreventBodyScroll();

  const onToggleAccount = useCallback(() => {
    setAccountVisible(state => !state);
    setNavVisible(false);
  }, [setAccountVisible, setNavVisible]);

  const onToggleNav = useCallback(() => {
    setNavVisible(state => !state);
    setAccountVisible(false);
  }, [setAccountVisible, setNavVisible]);

  const handleMenuItemClick = useCallback(() => {
    setAccountVisible(false);
    setNavVisible(false);
  }, [setAccountVisible, setNavVisible]);

  return {
    accountVisible,
    navVisible,
    onToggleAccount,
    onToggleNav,
    handleMenuItemClick,
  };
};

const useDesktop = () => {
  const [visible, setVisible] = useState(false);
  const parentRef = useRef();
  const menuRef = useRef();

  const onToggle = useCallback(() => {
    setVisible(state => !state);
  }, []);

  const onShow = useCallback(() => {
    setVisible(true);
  }, []);

  const onHide = useCallback(() => {
    setVisible(false);
  }, []);

  return {
    visible,
    parentRef,
    menuRef,
    onToggle,
    onShow,
    onHide,
  };
};

const Provider = ({ children }) => {
  const mobile = useMobile();
  const desktop = useDesktop();

  const contextValue = useMemo(
    () => ({
      mobile,
      desktop,
    }),
    [desktop, mobile],
  );

  return (
    <HeaderContext.Provider value={contextValue}>
      {children}
    </HeaderContext.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Provider;
