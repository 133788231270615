import { propType as styledSystemPropType } from '@styled-system/prop-types';
import { Button, Text } from '@trmediaab/zebra';
import { ZeroWidthSpace } from '@trmediaab/zebra-components';
import { useMouseLeave } from '@trmediaab/zebra-hooks';
import { Chevron } from '@trmediaab/zebra-icons';
import { useContext } from 'react';

import AccountCircle from '~/icons/AccountCircle';
import useAuthUser from '~/main/useAuthUser';

import { HeaderContext } from './HeaderContext';

const MouseLeaveContainer = () => {
  const {
    desktop: { parentRef, menuRef, onHide },
  } = useContext(HeaderContext);
  useMouseLeave([parentRef, menuRef], onHide);

  return null;
};

const DesktopAccount = ({ vspace }) => {
  const { name, email } = useAuthUser();

  const {
    desktop: { parentRef, visible, onShow, onToggle },
  } = useContext(HeaderContext);

  return (
    <>
      {visible && <MouseLeaveContainer />}
      <Button
        ref={parentRef}
        display="flex"
        fontWeight="inherit"
        onClick={onToggle}
        onMouseEnter={onShow}
        py={vspace}
      >
        <AccountCircle size="24px" color="greys.3" mr="1" />
        Inloggad som <ZeroWidthSpace />
        <Text.strong>{name != null ? name : email}</Text.strong>
        <Chevron
          direction={visible ? 'uo' : 'down'}
          size="20px"
          ml="1"
          mt="1"
        />
      </Button>
    </>
  );
};

DesktopAccount.propTypes = {
  vspace: styledSystemPropType.isRequired,
};

export default DesktopAccount;
