import { themeGet } from '@styled-system/theme-get';
import { Container, Position, Text } from '@trmediaab/zebra';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

import Info from '~/icons/Info';

const styleMap = {
  article: {
    bg: 'yellow',
  },
  expiringCard: {
    bg: 'state.errorBackground',
  },
};

const SiteAlert = ({ children, type }) => {
  const theme = useTheme();

  const { bg } = styleMap[type];

  return (
    <Position.relative bg={bg} zIndex="popoverContent">
      <Container flexDirection="row" pb="7px" pt="10px">
        <Info size="20px" color="text.primary" mt="1px" mr="2" flexShrink="0" />
        <Text.small fontWeight="semiBold">{children}</Text.small>
      </Container>
      {/* Cover the red border below */}
      <Position.absolute
        bg={bg}
        width="100%"
        sx={{
          bottom: `${themeGet('borderWidths.3')({ theme }) * -1}px`,
          height: `${themeGet('borderWidths.3')({ theme })}px`,
        }}
      />
    </Position.relative>
  );
};

SiteAlert.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['article', 'expiringCard']).isRequired,
};

export default SiteAlert;
