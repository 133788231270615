class UserModel {
  constructor(obj) {
    Object.assign(this, obj);
  }

  get lastJokersystemetSiteAccessed() {
    if (!this.hasAccessedJokersystemet) {
      return null;
    }

    const jokerSE = this.lastSiteAccess['jokersystemet'];
    const jokerNO = this.lastSiteAccess['jokersystemet_no'];

    if (jokerSE != null && jokerNO == null) {
      return 'jokersystemet';
    }

    if (jokerSE == null && jokerNO != null) {
      return 'jokersystemet_no';
    }

    return jokerNO > jokerSE ? 'jokersystemet_no' : 'jokersystemet';
  }

  get hasAccessedJokersystemet() {
    return (
      'jokersystemet' in this.lastSiteAccess ||
      'jokersystemet_no' in this.lastSiteAccess
    );
  }

  hasRole(role) {
    return this.roles.includes(role);
  }
}

export default UserModel;
