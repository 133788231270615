import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import PropTypes from 'prop-types';

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getProducts: {
          keyArgs: ['filter'],
          merge(existing, incoming, { args: { page = 1, limit } }) {
            const products = existing ? [...existing.products] : [];

            for (const [idx, product] of incoming.products.entries()) {
              products[(page - 1) * limit + idx] = product;
            }

            return {
              ...incoming,
              products,
            };
          },
        },
        getOrdersByUser: {
          keyArgs: false,
          merge(existing, incoming, { args: { page = 1, limit } }) {
            const orders = existing ? [...existing.orders] : [];
            for (const [idx, order] of incoming.orders.entries()) {
              orders[(page - 1) * limit + idx] = order;
            }
            return {
              ...incoming,
              orders,
            };
          },
        },
      },
    },
  },
});

const client = new ApolloClient({
  uri: '/api',
  cache,
  credentials: 'include',
  ssrMode: typeof window === 'undefined',
});

const Apollo = ({ children }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);

Apollo.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Apollo;
