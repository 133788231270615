import { useQuery } from '@apollo/client';
import { Button } from '@trmediaab/zebra';
import { useRouter } from 'next/router';
import { useCallback, useRef, useState } from 'react';

const useApolloQuery = (query, options, pageSize) => {
  const router = useRouter();
  const initialLimit = useRef();

  if (initialLimit.current == null && pageSize != null) {
    initialLimit.current =
      pageSize * (Number.parseInt(router.query.p, 10) || 1);
  }

  const variables = {
    ...options?.variables,
    ...(initialLimit.current != null && {
      page: 1,
      limit: initialLimit.current,
    }),
  };

  const { loading, fetchMore, ...queryRest } = useQuery(query, {
    ...options,
    variables,
  });

  const [fetchNextError, setFetchNextError] = useState(null);

  const fetchNextPage = useCallback(
    async (nextPage, updatePath) => {
      try {
        // To concatenate results, add query to ApolloClient cache
        await fetchMore({
          variables: {
            page: nextPage,
            limit: pageSize,
          },
        });

        if (updatePath) {
          router.replace(
            {
              pathname: router.pathname,
              query: { ...router.query, p: nextPage },
            },
            undefined,
            {
              shallow: true,
            },
          );
        }

        setFetchNextError(null);
      } catch (error) {
        setFetchNextError(error);
      }
    },
    [fetchMore, pageSize, router],
  );

  const renderNextPageButton = useCallback(
    ({ cacheLength, total, updatePath = true, labelFn }) => {
      // No more data to fetch
      if (cacheLength === total) {
        return null;
      }

      const nextPage = Math.floor(cacheLength / pageSize) + 1;

      return (
        <Button
          bc="secondary"
          size="xlarge"
          width="100%"
          disabled={loading}
          onClick={() => fetchNextPage(nextPage, updatePath)}
        >
          {loading
            ? '...Laddar'
            : labelFn(Math.min(pageSize, total - cacheLength))}
        </Button>
      );
    },
    [loading, fetchNextPage, pageSize],
  );

  return {
    ...queryRest,
    fetchNextError,
    loading,
    renderNextPageButton,
  };
};

export default useApolloQuery;
