import { useRef } from 'react';

import useRestQuery from './useRestQuery';

const useTraisQuery = query => {
  const memoQuery = useRef();

  if (memoQuery.current == null) {
    const { path } = query;
    memoQuery.current = {
      ...query,
      path: `/trais-api/${path}`,
      headers: { site: 'trhub' },
    };
  }

  return useRestQuery(memoQuery.current);
};

export default useTraisQuery;
