import { FlexBox } from '@trmediaab/zebra';
import PropTypes from 'prop-types';
import { createContext, useEffect, useMemo } from 'react';

import Query from '~/components/Query';

import { RedirectToLogin } from '../components/RedirectToLogin';
import Bugsnag from '../utils/bugsnag';
import query from './_authUser.gql';
import { userType } from './prop-types';
import UserModel from './UserModel';

export const UserContext = createContext(null);

const RenderLoggedIn = ({ user, children }) => {
  useEffect(() => {
    Bugsnag.setUser(user.id, user.email, user.name);
  }, [user]);

  const value = useMemo(() => new UserModel(user), [user]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

RenderLoggedIn.propTypes = {
  user: userType.isRequired,
  children: PropTypes.node.isRequired,
};

const UserProvider = ({ children }) => (
  <Query
    query={query}
    placeholder="firstLoad"
    wrap={(content, type) =>
      type === 'data' ? (
        content
      ) : (
        <FlexBox height="100vh" alignItems="center" justifyContent="center">
          {content}
        </FlexBox>
      )
    }
    renderError={errorComponent => errorComponent}
    render={data => {
      if (data == null) {
        return <RedirectToLogin />;
      }

      return (
        <RenderLoggedIn user={data.refreshUser}>{children}</RenderLoggedIn>
      );
    }}
  />
);

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserProvider;
