import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';

import theme from '@trmediaab/theme-trhub';
import { Text, ThemeProvider } from '@trmediaab/zebra';
import { useRouter } from 'next/router';
import Script from 'next/script';
import PropTypes from 'prop-types';

import ErrorBoundary from '~/components/ErrorBoundary';
import SiteLayout from '~/components/Layout/SiteLayout';
import Apollo from '~/main/ApolloClient';
import UserProvider from '~/main/UserProvider';

const App = ({ Component, pageProps }) => {
  const getLayout = Component.getLayout || (page => page);
  const router = useRouter();

  return (
    <ErrorBoundary>
      {process.env.NODE_ENV !== 'development' && (
        <Script
          id="gtm-script"
          key="gtm"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GTM_ID}');
          `,
          }}
        />
      )}
      <Apollo>
        <ThemeProvider
          theme={theme}
          disableVendorPrefixes={process.env.DISABLE_VENDOR_PREFIXES}
        >
          <Text.root height="100%" bg="background.primary">
            <UserProvider>
              <SiteLayout>
                {getLayout(
                  <ErrorBoundary>
                    <Component {...pageProps} />
                  </ErrorBoundary>,
                  router,
                )}
              </SiteLayout>
            </UserProvider>
          </Text.root>
        </ThemeProvider>
      </Apollo>
    </ErrorBoundary>
  );
};

App.propTypes = {
  Component: PropTypes.elementType,
  pageProps: PropTypes.shape({
    getLayout: PropTypes.func,
  }),
};

export default App;
