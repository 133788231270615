import { FlexBox } from '@trmediaab/zebra';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

export const RedirectToLogin = ({ loadingComponent }) => {
  const router = useRouter();

  useEffect(() => {
    router.push(
      `/oauth/authorize?dest=${encodeURIComponent(window.location.href)}`,
    );
  }, [router]);

  return loadingComponent != null ? (
    <FlexBox
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
    >
      {loadingComponent}
    </FlexBox>
  ) : null;
};

RedirectToLogin.propTypes = {
  loadingComponent: PropTypes.element,
};
