import { themeGet } from '@styled-system/theme-get';
import { Rhythm } from '@trmediaab/zebra';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';

const DefaultRenderer = ({ sx, ...props }) => {
  const theme = useTheme();
  const styles = useMemo(
    () => ({
      h2: {
        ...themeGet('components.heading.common')({ theme }),
        ...themeGet('components.heading.h3.variants.default')({ theme }),
      },
      h3: {
        ...themeGet('components.heading.common')({ theme }),
        ...themeGet('components.heading.h4.variants.default')({ theme }),
      },
      a: {
        ...themeGet('components.anchor.common')({ theme }),
        ...themeGet('components.anchor.variants.default')({ theme }),
      },
      strong: {
        ...themeGet('components.text.strong.variants.default')({ theme }),
      },
      'i, em': {
        ...themeGet('components.text.em.variants.default')({ theme }),
      },
      '*': {
        wordBreak: 'break-word',
      },
      ...sx,
    }),
    [theme, sx],
  );

  return <Rhythm between="4" sx={styles} {...props} />;
};

DefaultRenderer.propTypes = {
  sx: PropTypes.object,
};

export default DefaultRenderer;
