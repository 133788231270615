import { Anchor } from '@trmediaab/zebra';
import { Time } from '@trmediaab/zebra-components';
import { isBefore } from 'date-fns';
import Link from 'next/link';
import { paymentNetworks, sites } from 'trhub-utils';

import useAuthUser from '~/main/useAuthUser';

import Query from '../Query';
import SiteAlert from '../SiteAlert';
import query from './_GetExpiringCards.gql';

const ExpiringCardsAlert = () => {
  const { id } = useAuthUser();
  return (
    <Query
      query={query}
      options={{ variables: { id } }}
      wrap={(content, type) => (type === 'data' ? content : null)}
      render={({ getUser: { expiringCards } }) => {
        if (expiringCards.length === 0) {
          return null;
        }

        // Undo flex reverse order by reversing the array here
        return (
          <>
            {[...expiringCards].reverse().map(card => {
              const [subscriptionWithClosestDueDate] = card.subscriptions
                .filter(s => s.dueDate != null)
                .sort((a, b) => new Date(b.dueDate) - new Date(a.dueDate));

              // None of the subscriptions attached to this card has a due date.
              // Unlikely to happen but skip warning about this card just in case.
              if (subscriptionWithClosestDueDate == null) {
                return null;
              }

              const isExpired = isBefore(new Date(card.expiryDate), new Date());

              return (
                <SiteAlert key={card.id} type="expiringCard">
                  Ditt kort, {paymentNetworks[card.brand]} som slutar med{' '}
                  {card.maskedPan.slice(-4)},{' '}
                  {isExpired ? (
                    <>
                      har gått ut. Vänligen{' '}
                      <Anchor
                        as={Link}
                        href="/betalning/nytt-kort"
                        variant="matchText"
                        textDecoration="underline"
                      >
                        registrera ett nytt kort
                      </Anchor>
                    </>
                  ) : (
                    <>
                      går ut den{' '}
                      <Time date={new Date(card.expiryDate)} format="do MMMM" />
                      .{' '}
                      <Anchor
                        as={Link}
                        href="/betalning"
                        variant="matchText"
                        textDecoration="underline"
                      >
                        Vänligen uppdatera dina kortuppgifter
                      </Anchor>
                    </>
                  )}{' '}
                  innan{' '}
                  <Time
                    date={new Date(subscriptionWithClosestDueDate.dueDate)}
                    format="do MMMM"
                  />{' '}
                  då nästa dragning sker från{' '}
                  {sites[subscriptionWithClosestDueDate.product.site]}.
                </SiteAlert>
              );
            })}
          </>
        );
      }}
    />
  );
};

export default ExpiringCardsAlert;
